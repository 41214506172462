<template>
  <div class="container">

    <section class="orders-header">
      <h1 class="titlePage titlePage_textLeft">{{getUserName}}</h1>

      <breadcrumbs/>

      <p class="text text_green text_italic">Following packages are available for selected product. Select package to
        pre-populate settings or skip</p>

    </section>
    <div class="row"
         v-for="(item, key, index) in packages.packages"
         v-if="item.length"
    >
      <div class="col-12">
        <h2 class="titlePage_subtitle titlePage">{{key}}</h2>
      </div>
      <div class="col-md-4 col-sm-6"
           v-if="index !== 'name'"
           v-for="(p, index) in item"
           :key="item.id"
      >
        <div class="orderItem" @click="addPackage(p)">
          <div class="orderItem__listItems">

            <span class="orderItem__readMore-img">
              <svg-icon name="angle-down" class=""/>
            </span>

            <h3 class="orderItem__listTitle" :title="p.name">{{p.name}}</h3>

            <span class="orderItem__readMore-img" @click.stop="openContent($event)">
              <svg-icon name="angle-down"/>
            </span>

            <div class="orderItem__list">

              <div class="toggleList"
                   v-if="p.options"
              >
                <strong>Options:</strong>
                <ul class="">
                  <li class=""
                      v-for="option in p.options"
                      :key="option.id"
                  >
                    {{option.name}}
                  </li>
                </ul>
              </div>

              <div class="toggleList"
                   v-if="p.accessories">
                <strong>Accessories:</strong>
                <ul class="">
                  <li class=""
                      v-for="accessor in p.accessories"
                      :key="accessor.id"
                  >
                    {{accessor.name}}
                  </li>
                </ul>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

    <label class="form__label form__label_row">
      <input type="checkbox"
             hidden="hidden"
             class="form__checkbox"
             v-model="skip_packages"
             @change="checkSkip"
      >
      <span class="form__checkbox_icon"></span>
      <span class="form__labelTitle">Don't show this again</span>
    </label>
    <button type="button" class="btn btn_transparent skip" @click="skipStep">Skip this step</button>
  </div>
</template>

<script>
  import router from "../../router";
  import breadcrumbs from "./../parts/breadcrumbs";

  export default {
    name: "SelectPackage",

    data() {
      return {
        count: 0,
        skip_packages: JSON.parse(localStorage.getItem('skip_packages')),
      }
    },

    components: {
      breadcrumbs
    },

    methods: {
      openContent(e) {
        let target = e.target;
        target.closest('.orderItem').classList.toggle('orderItem_open');
      },

      addPackage(item) {
        this.$store.commit('createdOrder/updatePackagesOrder', item.id);

        let path = this.$store.getters['app/orderBuilderPath']('package_id>' + item.id);
        this.$store.dispatch(
          'app/addBreadcrumb',
          {
            route: {
              path: path
            },
            name: item.name,
            // disabled: true
          }
        );

        if (this.$router.currentRoute.name === 'sets-builder-style') {
          this.$parent.exchangeData = 'makeSetButtons';
        } else {
          this.$parent.exchangeData = 'selectColors';
        }

        router.push({path: path});
        this.$store.dispatch('packages/editPackageInOrder', item);
      },

      skipStep() {
        let path = this.$store.getters['app/orderBuilderPath']('package_id>' + 0);
        this.$store.dispatch(
          'app/addBreadcrumb',
          {
            route: {
              path: path
            },
            name: 'skip',
            disabled: true
          }
        );
        router.push({path: path});
        this.$store.commit('packages/clearPackage');
        this.$store.commit('createdOrder/clearPackagesOrder');
      },

      checkSkip() {
        localStorage.setItem('skip_packages', this.skip_packages);
      }
    },

    computed: {
      packages() {
        return this.$store.getters['packages/packagesForOrder'];
      },

      getUserName() {
        return localStorage.getItem('nameUser');
      },
    },

    created() {
      if (JSON.parse(localStorage.getItem('skip_packages'))) {
        this.$store.dispatch('packages/getPackageToOrder');
      }
      else {
        let path = this.$store.getters['app/orderBuilderPath']('package_id>' + 0);
        router.push({path: path});
        this.$store.commit('packages/clearPackage');
        this.$store.commit('createdOrder/clearPackagesOrder');
      }

      // return false;
    },

  }
</script>

<style scoped lang="scss">

  @import '../../assets/scss/utils/vars';

  .orderItem {
    position: relative;
    cursor: pointer;
    &__listTitle {
      color: $green;
      text-align: center;
      margin-bottom: 0;
      padding-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__footer {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .btn {
        margin: 0;
        width: 45%;
        display: block;
      }

    }

    ul {
      li {
        list-style: disc inside;
        display: list-item;
      }
    }
  }

  .orderItem__readMore-img {
    position: absolute;
    right: 10px;
    top: 15px;
    border: none;
    width: 15px;
    height: 15px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .orderItem .orderItem__list {
    height: 0;
    opacity: 0;
    display: none;
    transition: height .3s, opacity .1s;
  }

  .orderItem_open {
    .orderItem__list {
      height: auto;
      opacity: 1;
      display: block;
    }
    .orderItem__readMore-img {
      transform: rotate(180deg);
    }

  }

  .skip {
    position: absolute;
    bottom: 3rem;
    left: auto;
    right: 2rem;
    margin: 0;

    @media screen and (max-width: $sm){
      right: 1rem;
    }
  }

  .form__label {
    cursor: pointer;
    margin-bottom: 20px;
  }

  .form__labelTitle {
    color: $navy;
  }

  .form__checkbox + .form__checkbox_icon {
    background-color: $green;
  }

  .form__checkbox:checked + .form__checkbox_icon {
    background-color: transparent;
  }


</style>
